/* globals zoomSdk */
import { useEffect, useReducer, useState } from "react";
import { uniqWith, isEqual } from "lodash";
import { useList } from "react-firebase-hooks/database";
import { ref } from "firebase/database";
import { db } from "../firebase";

import { VIBE_NEUTRAL, VIBE_SAD, VIBE_SMILE } from "../constants";

// import { useInterval } from "usehooks-ts";

const POP = "pop";
const PUSH = "push";
const lookup = new Map();

function Reactions() {
  // have to set meetingId here
  // it looks like this component does re-render when meetingId gets set from App.js
  // meetingId = "12345";

  const [newVibes, setNewVibes] = useState([]);
  const [snapshots, loading, error] = useList(ref(db));
  const [firstFetch, setFirstFetch] = useState(true);
  const [reactionEmoji, setReactionEmoji] = useState(false);
  const [postCameraMessage, setCameraMessage] = useState("");
  const [meetingId, setMeetingId] = useState("");

  const animateEmoji = (emoji) => {
    const rand = Math.floor(Math.random() * 100 + 1);
    const flows = ["flowOne", "flowTwo", "flowThree"];
    //this changes how long emojis stay visible
    //bigger numger means slower animation
    const timing = (Math.random() * (1.3 - 1.0) + 1.0).toFixed(1);

    // eslint-disable-next-line no-undef
    $(`<div>${emoji}</div>`)
      .attr("class", "c-hearts__particle part-" + rand)
      .css({
        fontSize: Math.floor(Math.random() * (60 - 40) + 40) + "px",
        animation:
          flows[Math.floor(Math.random() * 3)] + " " + timing + "s linear",
      })
      .appendTo(".js-hearts");

    console.log("animating");

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      $(".js-hearts")
        .find(".part-" + rand)
        .remove();
    }, timing * 1000);
  };

  const reducer = (emojis, action) => {
    switch (action.type) {
      case POP:
        if (emojis.length === 0) {
          return [];
        }
        animateEmoji(emojis[0]);
        return emojis.slice(1);
      case PUSH:
        return [...emojis, action.emoji];
      default:
        return emojis;
    }
  };

  const [emojis, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    const initial = async () => {
      await zoomSdk.addEventListener("onMessage", (evt) => {
        const payload = JSON.parse(evt.payload);
        
        setMeetingId(payload.meetingId);

        // setCameraMessage(payload.count);
      });
    };

    initial();
  }, []);

  useEffect(() => {
    setInterval(() => {
      dispatch({ type: POP });
    }, 200);
  }, []);

  useEffect(() => {
    if (loading || meetingId === "") return;

    const newData = [];
    const vibes = uniqWith(
      snapshots
        .map((vibe) => vibe.val())
        .filter((vibe) => vibe.meetingId === meetingId),
      isEqual
    );
    vibes.forEach((vibe) => {
      const lookupVal = lookup.get(vibe.vibeId);
      if (!lookupVal) {
        newData.push(vibe);
        lookup.set(vibe.vibeId, vibe);
      }
    });

    if (!firstFetch) {
      setNewVibes([...newData]);
    }
    setFirstFetch(false);
  }, [firstFetch, snapshots, loading, meetingId]);

  useEffect(() => {
    if (meetingId === "") return;

    newVibes.forEach((vibe) => {
      switch (vibe.value) {
        case VIBE_NEUTRAL:
          // setReactionEmoji("😨");
          dispatch({ type: PUSH, emoji: "😨" });
          console.log("draw neutral emoji");
          break;
        case VIBE_SAD:
          // setReactionEmoji("😡");
          console.log("draw sad emoji");
          dispatch({ type: PUSH, emoji: "😡" });
          break;
        case VIBE_SMILE:
          // setReactionEmoji("🙂");
          dispatch({ type: PUSH, emoji: "🙂" });
          console.log("draw smile emoji");
          break;
        default:
        // do nothing
      }
    });
  }, [meetingId, newVibes]);

  return (
    <section class="reactions">
      {/* <h1>Camera Message: {postCameraMessage}</h1> */}
      {/* <h1>Meeting ID: {meetingId}</h1> */}
      {/* <h1>{emojis.toString()}</h1> */}
      <div class="c-hearts js-hearts"></div>
    </section>
  );
}

export default Reactions;
