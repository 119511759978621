import { useState } from 'react';
import Export from '../components/Export';
import Stats from '../components/Stats';

function Admin(props) {
    const { meetingId, onMeetingIdSubmit} = props;
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [formData, setFormData] = useState('');

    return (
        <section className="MeetingNew">
            <div className="flex min-h-full flex-col justify-center pb-4 sm:px-6 lg:px-8">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Enter Meeting ID
                    </p>

                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form
                            className="space-y-6"
                            onSubmit={(event) => {
                                setHasSubmitted(true);
                                onMeetingIdSubmit(formData);
                                event.preventDefault();
                            }}
                        >
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Meeting ID
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="number"
                                        name="number"
                                        type="number"
                                        autoComplete="number"
                                        required
                                        onChange={(event) => {
                                            setHasSubmitted(false);
                                            setFormData(event?.target?.value ?? '');
                                        }}
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    // disabled={!hasSubmitted}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {meetingId !== '' && hasSubmitted && (
                <>
                    <Stats meetingId={meetingId} />
                    {/* <Export meetingId={meetingId} /> */}
                </>
            )}
        </section>
    );
}

export default Admin;
