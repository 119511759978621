/* globals zoomSdk */
// import { useLocation, useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/solid";

// import { apis } from "./apis";
// import { Authorization } from "./components/Authorization";
// import ApiScrollview from "./components/ApiScrollview";
import Admin from "./pages/Admin";
import Reactions from "./pages/Reactions";

import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const [meetingId, setMeetingId] = useState("");
  const [showMainCards, setMainCards] = useState(false);
  const [showAdmin, setAdminView] = useState(false);
  const [cameraStatus, setCameraStatus] = useState(false);
  
  // const canvasRef = useRef(document.createElement("canvas"));
  
  const DEBUG = false; //Set true to see additional buttons in side panel
  
  const [runningContext, setRunningContext] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    const initial = async () => {
      const configRes = await zoomSdk.config({
        version: "0.16",
        size: { width: 480, height: 360 },
        popoutSize: { width: 1024, height: 720 },
        capabilities: [
          // The following are needed for Layers API.
          // Include any other capabilities your app needs here, too.
          "clearWebView",
          "closeRenderingContext",
          "drawWebView",
          "getRunningContext",
          "getVideoState",
          "onMessage",
          "onMyMediaChange",
          "openUrl",
          "postMessage",
          "runRenderingContext",
          // "authorize",
          // "clearImage",
          // "clearParticipant",
          // "drawImage",
          // "drawParticipant",
          // "getUserContext",
          // "onAuthorized",
          // "onBreakoutRoomChange",
          // "onMyReaction",
          // "onRenderedAppOpened",
          // "onRunningContextChange",
          // "sendAppInvitationToAllParticipants",
          // "setVideoMirrorEffect",
          // "shareApp",
        ],
      });

      let { context } = await zoomSdk.getRunningContext();
      setRunningContext(context);
      setMainCards(
        context && context !== "inCamera" && context !== "inImmersive"
      );

      // Get Camera Status
      let { video } = await zoomSdk.getVideoState();
      setCameraStatus(video);

      if (video) {
        runCameraContext();
        drawWebView();
      }

      // Check for Camera Changes
      await zoomSdk.addEventListener("onMyMediaChange", (evt) => {
        const { state } = evt.media.video;
        setCameraStatus(state);

        if (state) {
          runCameraContext();
          drawWebView();
        } else {
          closeRenderingContext();
          clearWebView();
        }
      });

    };

    initial();
  }, []);

  const WIDTH = 1280;
  const HEIGHT = 720;

  async function onMeetingIdSubmit(id) {
    setMeetingId(id);
    await zoomSdk.postMessage({ meetingId: id}) // Sends message to inCamera component
  }

  const runCameraContext = async () => {
    await zoomSdk.runRenderingContext({ view: "camera" });
  };

  const drawWebView = async () => {
    const webViewOptions = {
      webviewId: "camera",
      x: 0,
      y: 0,
      width: WIDTH,
      height: HEIGHT,
    };

    await zoomSdk.drawWebView(webViewOptions).then((response) => {
      let { message } = response;
      if (message === "Success") {
        setAdminView(true);
      }
    });
  };

  // Debug Mode
  const upVote = () => {
    setCount(count + 1);
    
    // zoomSdk.postMessage({ count: count + 1})
  };

  const closeRenderingContext = async () => {
    await zoomSdk.closeRenderingContext();
  };

  const clearWebView = async () => {
    const webViewOptions = {
      webviewId: "camera",
      x: 0,
      y: 0,
      width: WIDTH,
      height: HEIGHT,
    };

    await zoomSdk.clearWebView(webViewOptions);
  };

  return (
    <div className="App">
      {runningContext === "inCamera" && <Reactions meetingId={meetingId} />}

      {showMainCards && (
        <div>
          <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center items-center">
            <ChatBubbleBottomCenterTextIcon className="h-12 text-blue-500" />
            <h2 className="px-2 text-3xl font-bold tracking-tight text-gray-900">
              what's the vibe?
            </h2>
          </div>

          { !cameraStatus && <div className="flex justify-between py-4">Please turn on your camera...</div> }

          { showAdmin && cameraStatus && (
            <Admin
              className="mb-2"
              meetingId={meetingId}
              onMeetingIdSubmit={onMeetingIdSubmit}
            />
          )}

          { DEBUG && (
          <div>
            <h2>Context</h2>
            <p>Running Context: {runningContext}</p>

            <div className="flex flex-col	justify-between pb-4">
              <Button
                className="mb-2"
                onClick={async () => {
                  await upVote();
                }}
              >
                Upvote
              </Button>
              {count}

              <Button
                className="mb-2"
                onClick={async () => {
                  await runCameraContext();
                }}
              >
                Run Camera Context
              </Button>

              <Button
                className="mb-2"
                onClick={async () => {
                  await closeRenderingContext();
                }}
              >
                Close Rendering Context
              </Button>
            </div>

            <h2>Web View</h2>
            <div className="flex justify-between pb-4">
              <Button
                onClick={async () => {
                  await drawWebView();
                }}
              >
                Draw Web View
              </Button>

              <Button
                onClick={async () => {
                  await clearWebView();
                }}
              >
                Clear Web View
              </Button>
            </div>

            </div> 
          )}
        </div>
      )}
    </div>
  );
}

export default App;
